import "./App.css";
import React, { useEffect } from "react";
import { Route, Routes, Outlet, useNavigate, useLocation } from "react-router-dom";
import { URL_ROUTES } from "./data/constants";
import { readToken, isTokenExpired } from "./services/sessionService";
// import icons
// import "bootstrap-icons/font/bootstrap-icons.css";

// import Pages
import Login from "./pages/Login/Login.tsx";
import AgentAnalytics from "./pages/AgentAnalytics/AgentAnalytics.tsx";
import PageNotFound from "./pages/PageNotFound/PageNotFound.tsx";

// import components
import Header from "./components/Header/Header.tsx";
import Sidebar from "./components/Sidebar/Sidebar.tsx";

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

function App() {
  const Layout = () => {
    const navigate = useNavigate();
    const location = useLocation();

    /* START: check login session for every route change ---------------------------------- */
    const token = readToken();
    useEffect(() => {
      if (!token) {
        navigate(`/${URL_ROUTES.login}`);
      } else {
        if (isTokenExpired()) {
          localStorage.clear();
          navigate(`/${URL_ROUTES.login}`);
        } else if (location.pathname === "/") {
          navigate(`/${URL_ROUTES.agentAnalytics}`);
        }
      }
    }, [navigate, location]);
    /* END: check login session for every route change ------------------------------------ */

    return (
      <div className="layout">
        <Header />
        <Sidebar />
        <main id="main" className="main">
          <Outlet />
        </main>
      </div>
    );
  };

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<Layout />}>
        <Route path="agent-analytics" element={<AgentAnalytics />} />
      </Route>
      {/* Wildcard route for 404 Page */}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
export default App;

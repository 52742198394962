import React, { useState, useEffect } from "react";
import axios from "axios";
import AgentCharts from "./AgentCharts/AgentCharts.tsx";
import AgentCounts from "./AgentCounts/AgentCounts.tsx";
import AgentConversation from "./AgentConversation/AgentConversation.tsx";
import "./AgentAnalytics.css";
import AgentDetails from "./AgentDetails/AgentDetails.tsx";
import { toastError } from "../../components/Toast/Toast"; // components
import { readToken } from "../../services/sessionService";
import { API_BASE_URL, API_ENDPOINT } from "../../data/constants"; // common data

function AgentAnalytics() {
  const token = readToken();
  const [isLoading, setIsLoading] = useState(false);
  const [showComingSoon, setShowComingSoon] = useState(true); // Flag to control "Coming Soon"

  /* START: Get Agent List ------------------------------------------------------------ */
  const [agentList, setAgentList] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState({ agent_id: "", agent_name: "", agent_phone: "", call_type: "" });

  useEffect(() => {
    !agentList.length && token && getAgentListAPI();
  }, [agentList, token]);

  const getAgentListAPI = () => {
    setIsLoading(true);
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    try {
      axios
        .get(`${API_BASE_URL}${API_ENDPOINT.agent.agentList}?skip=0&limit=100`, { headers: headers })
        .then((response) => {
          if (response?.data?.success) {
            setAgentList(response.data.data.agents);
            setSelectedAgent(response.data.data.agents.length ? response.data.data.agents[0] : "");
          } else {
            toastError("Failed to Refresh Agent List.");
          }
          setIsLoading(false);
        })
        .catch((error) => {
          toastError("Failed to load Agent List.");
          setIsLoading(false);
        });
    } catch (error) {
      toastError("Something went wrong, please try again!");
    }
  };

  const handleTabSelect = (agent) => {
    setSelectedAgent(agent);
    const tabElement = document.getElementById(agent.agent_id);
    if (tabElement) {
      tabElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
    }
  };

  return (
    <section id="agent-analytics" className="agent-analytics section">
      <div className="row">
        {/* Left Section */}
        <div className="col-lg-6 left-section">
          {/* Agent Tabs and Create Agent Button */}
          <div className="d-flex agent-tabs-container">
            <div className="agent-tabs d-flex m-0">
              {agentList &&
                agentList.map((agent: any) => (
                  <button key={agent?.agent_name} onClick={() => { handleTabSelect(agent); setSelectedAgent(agent) }} id={agent.agent_id} className={`agent-tab ${selectedAgent?.agent_name === agent.agent_name ? "active" : ""}`} >
                    {agent.agent_name}
                  </button>
                ))}
            </div>

            {/* Create Agent Button */}
            <button className="btn btn-primary btn-skew disabled">
              <span>+ Create Agent</span>
            </button>
          </div>

          <div className="row">
            <div className="col-12 agent-charts">
              {/* AgentDetails component */}
              <AgentDetails selectedAgent={selectedAgent} />

              {/* Conditionally render charts or "Coming Soon" message */}
              {showComingSoon ? (
                <div className="coming-soon-container agent-charts">
                  <div className="coming-soon-message">
                    <h3>AI powered Analytics</h3>
                    <p>We are working on this feature, stay tuned!</p>
                  </div>
                </div>
              ) : (
                <AgentCharts />
              )}
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div className="col-lg-6 right-section">
          <div className="row">
            <div className="col-12 agent-conversation">
              <h6 style={{ margin: "5px" }}>Conversations</h6>
              <AgentCounts agentId={selectedAgent.agent_id} />
              <AgentConversation selectedAgent={selectedAgent} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AgentAnalytics;

import React, { useState } from "react";
import Chart from "react-apexcharts";

function QuestionChart() {
  const [data, setData] = useState({
    series: [60, 30, 10],
    options: {
      title: {
        text: "Queries",
        align: "left",
        style: {
          fontSize: "15px",
        },
      },
      labels: ["Query 1", "Query 2", "Query 3"],
      chart: {
        height: 300,
        type: "pie",
        toolbar: {
          show: false,
        },

        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    },
  });

  return <Chart options={data.options} series={data.series} type={data.options.chart.type} height={data.options.chart.height} />;
}

export default QuestionChart;

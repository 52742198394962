// packages
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer } from "react-toastify";

import { toastError } from "../../components/Toast/Toast"; // components
import { isTokenExpired } from "../../services/sessionService";
import { API_BASE_URL, API_BASE_URL2, API_ENDPOINT, URL_ROUTES } from "../../data/constants"; // common data

// images
import logo from "../../assets/logo.png";
import siteScreen from "../../assets/login-frame.png";

import "./Login.css";

function Login() {
  const navigate = useNavigate();

  /* START: check login session and redirect to agentAnalytics page if session is valid ----------- */
  useEffect(() => {
    if (!isTokenExpired()) {
      navigate(`/${URL_ROUTES.agentAnalytics}`);
    } else {
      localStorage.clear();
    }
  }, []);
  /* END: check login session and redirect to agentAnalytics page if session is valid ------------- */

  /* START: Login Operations ------------------------------------------------------------ */
  const [loginData, setLoginData] = useState({ email: "", password: "" });

  const handleLoginChange = (event) => {
    setLoginData({ ...loginData, [event.target.name]: event.target.value });
  };

  const handleLoginSubmit = (event) => {
    callLoginAPI();
    event.preventDefault();
  };

  const callLoginAPI = () => {
    try {
      axios
        .post(`${API_BASE_URL}${API_ENDPOINT.login}`, loginData)
        .then((response) => handleLoginResponse(response))
        .catch((error) => {
          toastError(error?.response?.data?.message || error?.response?.data?.detail || "Please try again!");
          console.log(error);
        });

        axios
        .post(`${API_BASE_URL2}${API_ENDPOINT.login}`, loginData)
        .then((response) => handleLoginResponse2(response))
        .catch((error) => {
          // toastError(error?.response?.data?.message || error?.response?.data?.detail || "Please try again!");
          console.log(error);
        });

    } catch (error) {
      // toastError("Something went wrong, please try again!");
    }
  };

  const handleLoginResponse = (response) => {
    if (response.data?.success) {
      localStorage.setItem("loginData", JSON.stringify(response.data.data));
      navigate(`/${URL_ROUTES.agentAnalytics}`);
    } else {
      toastError("Failed to Login.");
    }
  };

  const handleLoginResponse2 = (response) => {
    if (response.data?.success) {
      localStorage.setItem("loginData2", JSON.stringify(response.data.data));
      // navigate(`/${URL_ROUTES.agentAnalytics}`);
    } else {
      // toastError("Failed to Login.");
    }
  };
  /* END: Login Operations -------------------------------------------------------------- */

  return (
    <div id="login" className="login-container">
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

      <div className="row">
        {/* Left Side - Login Form */}
        <div className="col-4">
          <div className="login-left">
            {/* logo */}
            <img src={logo} className="login-left-logo" alt="logo" />

            {/* form */}
            <h2 className="text-center">Sign in with Email ID</h2>
            <form onSubmit={handleLoginSubmit}>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email ID
                </label>
                <input type="email" className="form-control" id="email" name="email" required onChange={handleLoginChange} />
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <input type="password" className="form-control" id="password" name="password" required onChange={handleLoginChange} />
              </div>
              <div className="mb-3 text-center">
                <button type="submit" className="btn btn-sm btn-primary btn-login-submit">
                  Sign in
                </button>
              </div>
            </form>
          </div>
        </div>

        {/*Right Side - Site Mini Screens */}
        <div className="col-6 d-sm-none d-md-block">
          <div className="login-right">
            {/* logo */}
            {/* <div className="login-right-logo">
              <img src={logo} alt="logo" />
            </div>
            <h2 className="text-center">Say Hello to Smarter Calls!</h2> */}
            <div className="login-right-screens">
              <img src={siteScreen} width={780} alt="screens" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;

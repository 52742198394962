import React from "react";

function ErrorPage() {
  return (
    <div className="text-center p-4">
      <h1 className="text-dark">Not Found</h1>
      <h5>This page doesn't exist.</h5>
    </div>
  );
}

export default ErrorPage;

import React from "react";
import { Link, useLocation } from "react-router-dom";
import { URL_ROUTES } from "../../data/constants";
import agentsIcon from '../../assets/icons/agents.png';
import "./Sidebar.css";

function SideBar() {
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  return (
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        <li className={isActive(`/${URL_ROUTES.agentAnalytics}`) ? `nav-item active` : "nav-item"}>
          <Link to={`/${URL_ROUTES.agentAnalytics}`} className="nav-link">
            <span>
              <img src={agentsIcon} width={20} alt="Agent" />
            </span>
          </Link>
        </li>
      </ul>
    </aside>
  );
}

export default SideBar;

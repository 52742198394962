import React from "react";
import "./Logo.css";
import logo from "../../../assets/logo.png";
function Logo() {
  // const handleToggleSideBar = () => {
  //   document.body.classList.toggle("toggle-sidebar");
  // };

  return (
    <div className="d-flex align-items-center justify-content-between">
      <a href="/" className="logo d-flex align-items-center">
        <img src={logo} width={115} alt="logo" />
      </a>
      {/* <i className="bi bi-list toggle-sidebar-btn" onClick={handleToggleSideBar}></i> */}
    </div>
  );
}

export default Logo;

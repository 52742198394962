export const URL_ROUTES = {
  login: "login",
  dashboard: "dashboard",
  agentAnalytics: "agent-analytics",
};
export const API_BASE_URL = "https://backend.stage.vozzo.ai/";
export const API_BASE_URL2 = "https://smart-call-backend.stage.vozzo.ai/";

// export const API_BASE_URL = "https://backend.vozzo.ai/"; 
// export const API_BASE_URL2 = "https://smart-call-backend-services.vozzo.ai/";

// export const API_BASE_URL = "http://localhost:8000/";
export const API_ENDPOINT = {
  login: "auth/login",
  register: "auth/register",
  profile: {
    getProfile: "auth/profile",
  },
  agent: {
    agentList: "agent/list-agent/",
    agentConversation: "agent/agent-conversation",
    agentCounts: "agent/conversation-counts",
    createCall: "ai-voz-agents/dvara-loan-recovery-exotel/"
  },

  // base data
  // baseLanguageData: "campaign/lang-data/",
};

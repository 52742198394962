import React, { useState, useEffect } from "react";
import "./AgentCounts.css";
import { FaPhone } from "react-icons/fa";
import { PiArrowSquareUpRightFill } from "react-icons/pi";
import { RiErrorWarningFill } from "react-icons/ri";
import { MdAlarm } from "react-icons/md";
import axios from "axios";
import { API_BASE_URL, API_ENDPOINT } from "../../../data/constants";
import { readToken } from "../../../services/sessionService";
import { toastError } from "../../../components/Toast/Toast"; // components

function AgentCounts({ agentId }) {
  const token = readToken();

  const [cards, setCards] = useState([
    {
      _id: 1,
      name: "Total Calls",
      icon: FaPhone,
      amount: 0,
      backgroundColorStart: "#35B1EB",
      backgroundColorEnd: "#1492CD",
    },
    // {
    //   _id: 2,
    //   name: "Issues Resolved",
    //   icon: PiArrowSquareUpRightFill,
    //   amount: 0, // Set this dynamically as well
    //   backgroundColorStart: "#07E4A3",
    //   backgroundColorEnd: "#2B9878",
    // },
    // {
    //   _id: 3,
    //   name: "Escalations",
    //   icon: RiErrorWarningFill,
    //   amount: 0, // Set this dynamically as well
    //   backgroundColorStart: "#D55656",
    //   backgroundColorEnd: "#D52828",
    // },
    {
      _id: 4,
      name: "Avg Call Duration",
      icon: MdAlarm,
      amount: "4.5 min",
      backgroundColorStart: "#F0DA4A",
      backgroundColorEnd: "#C7AF0F",
    },
  ]);

  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}${API_ENDPOINT.agent.agentCounts}/${agentId}/`, { headers: headers });
        const data = response.data.data;

        setCards((prevCards) =>
          prevCards.map((card) => {
            if (card._id === 1) {
              return { ...card, amount: data.total };
              // } else if (card._id === 2) {
              //   return { ...card, amount: data.issuesResolved };
              // } else if (card._id === 3) {
              //   return { ...card, amount: data.escalations };
            } else if (card._id === 4) {
              return { ...card, amount: data.avg_call_duration };
            }
            return card;
          })
        );
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    agentId && fetchData();
  }, [agentId]);

  return (
    <div className="custom-row">
      {cards.map((card) => (
        <div className="custom-card" key={card._id}>
          <div
            className="card agent-counts-card text-center"
            style={{
              background: `linear-gradient(180deg, ${card.backgroundColorStart} 0%, ${card.backgroundColorEnd} 100%)`,
            }}
          >
            <div className="card-icon">
              <card.icon size={25} />
            </div>
            <div className="card-name">
              <small>{card.name}</small>
            </div>
            <div className="card-count">
              <h6 className="count">{card.amount}</h6>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default AgentCounts;

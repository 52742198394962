import React from "react";
import CallChart from "./CallChart.tsx";
import QuestionChart from "./QuestionChart.tsx";

function AgentCharts() {
  return (
    <div className="card agent-charts" style={{ maxHeight: '60vh' }}>
      <div className="card-body" style={{ overflowY: 'scroll' }} >
        <h6 style={{ margin: "5px" }}>Analytics</h6>
        {/* Charts Section - Two Bar Charts side by side */}
        <div className="row">
          <div className="col-md-6">
            <div className="call-chart border-1">
              <CallChart />
            </div>
          </div>
          <div className="col-md-6">
            <div className="another-bar-chart border-1">
              <CallChart />
            </div>
          </div>
        </div>

        {/* Pie Chart Section below the Bar Charts */}
        <div className="row mt-3">
          <div className="col-12">
            <div className="pie-chart">
              <QuestionChart />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgentCharts;

import React, { useState, useEffect } from "react";
import axios from "axios";
// import { ToastContainer } from "react-toastify";
import ReactPaginate from "react-paginate";
import "./AgentConversation.css";
import { FaAlignLeft } from "react-icons/fa6";

import { toastError } from "../../../components/Toast/Toast";
import { readToken } from "../../../services/sessionService";
import { API_BASE_URL, API_ENDPOINT } from "../../../data/constants";
import { Modal, Button } from "react-bootstrap";
import customer_avatar from "../../../assets/customer_avatar1.png"
import agent_avatar from "../../../assets/agent_avatar.png"
import { MdOutlineTranscribe } from "react-icons/md";
import { Spinner } from "react-bootstrap";

type Conversation = {
  conv_id: string;
  cust_name: string;
  cust_phone: string;
  call_direction: string;
  date: string;
  time: string;
  duration: string;
  conv_status: string;
  conv_detail?: { role: string; content: string }[] | string;
  conv_summary?: string | Summary;
};

type Summary = {
  customer_interested: boolean;
  customer_concerns: string[];
  customer_feedback: string;
  customer_sentiment: string;
  answers_for_questions: string[];
};


function AgentConversation({ selectedAgent }) {
  const token = readToken();
  const [isLoading, setIsLoading] = useState(true);

  // Table Related
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10; // Define how many items per page you want

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  /* START: Get Conversation Data By Agent Id Operations ------------------------------------------------------------ */
  const [conversationList, setConversationList] = useState([]);
  const [totalLength, setTotalLength] = useState(0);
  const [selectedConversation, setSelectedConversation] = useState<Conversation | null>(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    selectedAgent?.agent_id && token && callGetConversationListAPI(currentPage);
  }, [token, selectedAgent, currentPage]);

  const callGetConversationListAPI = (page = 0) => {
    setIsLoading(true);

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    // toastInfo("Loading data, please wait.");
    try {
      axios
        .get(`${API_BASE_URL}${API_ENDPOINT.agent.agentConversation}/${selectedAgent?.agent_id}/?skip=${page * 10}&limit=${itemsPerPage}`, { headers: headers })
        .then((response) => {

          if (response?.data?.success) {
            setConversationList(response.data.data.conversations);
            setTotalLength(response.data.data.total);
          } else {
            toastError("Failed to Refresh Conversation List.");
          }
          setIsLoading(false);
        })
        .catch((error) => {
          toastError("Failed to load Conversation List.");
          setIsLoading(false);
        });
    } catch (error) {
      toastError("Something went wrong, please try again!");
    }
  };
  /* END: Get Conversation Data By Agent Id Operations ------------------------------------------------------------ */

  // Get current items to display for the current page
  // const displayedConversations = conversationList.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

  const handleStatus = (status) => {
    switch (status) {
      case "Completed":
        return "#22D36E";
      case "In Queue":
        return "#F19A61";
      case "Pending":
        return "primary";
      case "Rejected":
        return "danger";
      default:
        return "secondary";
    }
  };

  const handleOpenModal = (conversation) => {
    setSelectedConversation(conversation);
    setShowModal(true);
  };

  const parseFunction = (data) => {
    if (data) {
      return JSON.parse(data);
    } else {
      return data
    }
  }

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      weekday: "short",
      year: "2-digit",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
  };

  // const handleCopySummary = () => {
  //   const summaryText =
  //     typeof selectedConversation?.conv_summary === "string"
  //       ? selectedConversation.conv_summary
  //       : JSON.stringify(selectedConversation?.conv_summary || {});
  //   navigator.clipboard.writeText(summaryText);
  // };



  return (
    <div id="agent-conversation" className="agent-conversation">
      {/* Filter Section */}
      {/* <div className="filter d-flex align-items-center justify-content-start">
        <label htmlFor="">Period : </label>
        <select className="form-select form-select-sm me-2">
          <option value="last-10-days">Last 10 Days</option>
          <option value="last-week">Last Week</option>
          <option value="last-month">Last Month</option>
        </select>
        <label htmlFor="">Status : </label>
        <select className="form-select form-select-sm">
          <option value="Resolved">All</option>
          <option value="Pending">Pending</option>
          <option value="Rejected">Rejected</option>
        </select>
      </div> */}

      {/* Table Section */}
      <div className="table-container" style={{ height: "365px", overflowY: "auto" }}>
        <table className="table table-borderless table-responsive-sm">
          <thead >
            <tr>
              <th scope="col">Ticket</th>
              <th scope="col">Name</th>
              <th scope="col">Call Type</th>
              <th scope="col">Phone Number</th>
              <th scope="col">Date & Time</th>
              <th scope="col">Duration</th>
              <th scope="col">Status</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={8} className="text-center">
                  <div className="loading-container">
                    <Spinner animation="border" className="spinner-custom" size="sm" />
                    <span className="ms-2">Loading...</span>
                  </div>
                </td>
              </tr>
            ) : conversationList.length > 0 ? (
              conversationList.map((item: any, index) => (
                <tr
                  key={index}
                  className={index % 2 === 0 ? "table-row-alt" : ""}
                >
                  <td>#{item.conv_id || '--'}</td>
                  <td>{JSON.parse(item.payload)?.cust_name || '--'}</td>
                  <td className="text-capitalize">{item.call_direction || '--'}</td>
                  <td>{item.cust_phone || '--'}</td>
                  <td>{item.start_time || '--'}</td>
                  <td>{item.duration || '--'}</td>
                  <td>
                    <span style={{ backgroundColor: handleStatus(item.conv_status) }} className={`badge`}>
                      {item.conv_status}
                    </span>
                  </td>
                  <td>
                    <MdOutlineTranscribe
                      size={20}
                      title="Transcript"
                      style={{ marginTop: '7px', cursor: 'pointer', color: '#69ABCB' }}
                      onClick={() => handleOpenModal(item)}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8} className="text-center">No Data</td>
              </tr>
            )}
          </tbody>

        </table>
      </div>

      {/* Pagination */}
      <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        breakLabel={"..."}
        pageCount={Math.ceil(totalLength / itemsPerPage)} // Total number of pages
        onPageChange={handlePageChange}
        containerClassName={"pagination-sm pagination justify-content-center"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        activeClassName={"active"}
      />



      {/* Modal Section */}
      {showModal && selectedConversation && (
        <Modal show={showModal} onHide={() => setShowModal(false)} centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title><h5 className="pt-2">Ticket #{selectedConversation?.conv_id}</h5></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex">
              <div className="modal-left" style={{ flex: 1 }}>
                <h6>Transcript</h6>
                <div className="transcript" style={{
                  maxHeight: "300px",
                  overflowY: "auto",
                  padding: "15px",
                  borderRadius: "10px",
                  background: '#E2F4FC',
                  fontSize: '14px'
                }}>
                  {Array.isArray(parseFunction(selectedConversation.conv_detail)) ? (
                    parseFunction(selectedConversation.conv_detail).map((entry: any, index) => (
                      <div key={index} className={`transcript-entry transcript-${entry.role}`}>
                        {/* Avatar and Role-based Color */}
                        <div className="transcript-header">
                          <img
                            src={entry.role === "user" ? `${customer_avatar}` : `${agent_avatar}`}
                            alt={entry.role === "user" ? "Customer Avatar" : "Agent Avatar"}
                            className="transcript-avatar"
                          />
                          <strong className={entry.role === "user" ? "customer-role" : "agent-role"}>
                            {entry.role === "user" ? "Customer" : selectedAgent?.agent_name || 'Bot'}:
                          </strong>
                        </div>

                        {/* Content */}
                        <div className="transcript-content" style={{ flex: 1 }}>
                          {entry.content}
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No transcript available.</p>
                  )}

                  {/* {Array.isArray(parseFunction(selectedConversation.conv_detail)) ? (
                    parseFunction(selectedConversation.conv_detail).map((entry: any, index) => (
                      <div key={index} className={`transcript-entry transcript-${entry.role}`}>
                        <strong>{entry.role === "user" ? "Customer" : selectedAgent?.agent_name || 'Bot'}:</strong> {entry.content}
                      </div>
                    ))
                  ) : (
                    <p>No transcript available.</p>
                  )} */}
                </div>
                {/* <Button variant="outline-primary" className="mt-3">
                  <FaDownload className="me-2" /> Download
                </Button> */}
              </div>
              {/* <div className="modal-right" style={{ flex: 1 }}>
                <div className="summary-header d-flex justify-content-between align-items-center">
                  <h6>Summary</h6>
                  <FaCopy className="copy-icon" onClick={handleCopySummary} style={{ cursor: "pointer" }} />
                </div>
                <div className="summary-content mt-3">
                  {parseFunction(selectedConversation.conv_summary) ? (
                    <>
                      <div>
                        <strong>Customer Interested:</strong> {parseFunction(selectedConversation.conv_summary)?.customer_interested ? "Yes" : "No"}
                      </div>
                      <div>
                        <strong>Customer Sentiment:</strong> {parseFunction(selectedConversation.conv_summary)?.customer_sentiment}
                      </div>
                      {parseFunction(selectedConversation.conv_summary)?.customer_concerns.length > 0 && (
                        <div>
                          <strong>Customer Concerns:</strong>
                          <ul>
                            {parseFunction(selectedConversation.conv_summary).customer_concerns.map((concern, index) => (
                              <li key={index}>{concern}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                      {parseFunction(selectedConversation.conv_summary)?.customer_feedback && (
                        <div>
                          <strong>Customer Feedback:</strong> {parseFunction(selectedConversation.conv_summary).customer_feedback}
                        </div>
                      )}
                      {parseFunction(selectedConversation.conv_summary)?.answers_for_questions.length > 0 && (
                        <div>
                          <strong>Answers for Questions:</strong>
                          <ul>
                            {parseFunction(selectedConversation.conv_summary).answers_for_questions.map((answer, index) => (
                              <li key={index}>{answer}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </>
                  ) : (
                    <p>No summary available.</p>
                  )}
                </div>
              </div> */}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => setShowModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}

    </div>
  );
}

export default AgentConversation;

import React from "react";
import "./Header.css";

import Logo from "./Logo/Logo.tsx";
import Navbar from "./Navbar/Navbar.tsx";

function Header() {
  return (
    <header id="header" className="fixed-top d-flex align-items-center">
      <Logo />
      <Navbar />
    </header>
  );
}

export default Header;

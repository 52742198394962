import React, { useState } from "react";
import Chart from "react-apexcharts";

function CallChart() {
  const [data, setData] = useState({
    series: [
      {
        name: "Total Calls",
        data: [500, 450, 520, 550, 600, 780],
      },
      {
        name: "Connected",
        data: [350, 400, 480, 520, 550, 750],
      },
      {
        name: "Abandoned",
        data: [280, 350, 400, 450, 500, 600],
      },
    ],
    options: {
      title: {
        // text: "Calls",
        floating: true,
        align: "left",
        style: {
          fontSize: "12px",
        },
      },
      chart: {
        type: "bar",
        height: 350,
      },

      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        curve: "smooth",
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "June"],
      },
      fill: {
        opacity: 1,
      },
    },
  });

  return <Chart options={data.options} series={data.series} type={data.options.chart.type} height={data.options.chart.height} />;
}

export default CallChart;

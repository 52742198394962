import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import avatar from "../../../../assets/avatar.png";
import { URL_ROUTES } from "../../../../data/constants";
import { readProfile, logOut } from "../../../../services/sessionService";
import "./NavAvatar.css";
import { FaCoins } from "react-icons/fa6";
import { readToken } from "../../../../services/sessionService";
import axios from "axios";
import { API_BASE_URL, API_ENDPOINT } from "../../.../../../../data/constants"; // common data
import { toastError } from "../../../../components/Toast/Toast"; // components

function NavAvatar() {
  const token = readToken();
  const [profile, setProfile] = useState<any | null>();
  /* START: get profile details --------------------------------------------------------- */
  useEffect(() => {
    token && getProfileDetails();
  }, [token]);

  /* START: Common API Params ----------------------------------------------------------- */
  const getHeaderPayload = () => {
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
  };
  /* END: Common API Params ------------------------------------------------------------- */

  const getProfileDetails = () => {
    try {
      axios
        .get(`${API_BASE_URL}${API_ENDPOINT.profile.getProfile}`, { headers: getHeaderPayload() })
        .then((response) => {
          if (response?.data?.success) {
            localStorage.setItem("profile", JSON.stringify(response.data.data));
            setProfile(response.data.data);
            console.log(profile);
          } else {
            toastError("Failed to load Profile Data.");
          }
        })
        .catch((error) => {
          toastError("Failed to load Profile Data, please reload the load");
        });
    } catch (error) {
      toastError("Failed to load Profile Data, please reload the load!");
    }
  };
  /* END: get profile details ----------------------------------------------------------- */

  const navigate = useNavigate();
  const handleLogout = () => {
    logOut();
    navigate(`/${URL_ROUTES.login}`);
  };

  return (
    <>
      <span style={{ color: "#1C80AF" }} className="d-none d-md-block pe-2">
        {profile?.credit_points} &nbsp; Credits
      </span>
      <FaCoins color="#0282BE" style={{ marginLeft: "5px" }} /> &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
      <li className="nav-item dropdown">
        <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          <span className="d-none d-md-block dropdown-toggle pe-2">{profile?.name}</span>
          <img src={avatar} alt="profile" className="rounded-circle" />
        </a>
        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
          <li className="dropdown-header">
            <h6>{profile?.name}</h6>
          </li>
          <li>
            <hr className="dropdown-divider" />
          </li>
          {/* <li>
          <a className="dropdown-item d-flex align-items-center" href="">
            <i className="bi bi-person"></i>
            <span>My Profile</span>
          </a>
        </li> */}
          <li>
            <hr className="dropdown-divider" />
          </li>
          <li>
            <a className="dropdown-item d-flex align-items-center" onClick={handleLogout}>
              <i className="bi bi-box-arrow-right"></i>
              <span>Sign Out</span>
            </a>
          </li>
        </ul>
      </li>
    </>
  );
}
export default NavAvatar;

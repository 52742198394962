import React, { useState } from "react";
import "./AgentDetails.css";
import countryLogoVikram from "../../../assets/vikram-flag.jpg";
import { IoMdCall } from "react-icons/io";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { API_BASE_URL2, API_ENDPOINT } from "../../../data/constants";
import { toastError, toastSuccess } from "../../../components/Toast/Toast";

type Errors = {
  name: string;
  language: string;
  loan_amount: string;
  due_date: string;
  phone_number: string;
};


function AgentDetails({ selectedAgent }) {

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    name: "",
    language: "",
    loan_amount: "",
    due_date: "",
    phone_number: "",
  });


  const [errors, setErrors] = useState<Errors>({
    name: "",
    language: "",
    loan_amount: "",
    due_date: "",
    phone_number: "",
  });


  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" }); // Clear error on input change
  };

  const handleClose = () => {
    setShow(false);
    setFormData({
      name: "",
      language: "",
      loan_amount: "",
      due_date: "",
      phone_number: "",
    });
    setErrors({
      name: "",
      language: "",
      loan_amount: "",
      due_date: "",
      phone_number: "",
    });
  };

  const validateForm = (): boolean => {
    const newErrors: Errors = {
      name: "",
      language: "",
      loan_amount: "",
      due_date: "",
      phone_number: "",
    };

    // Check if any field is empty
    Object.keys(formData).forEach((key) => {
      if (!formData[key as keyof FormData]) {
        newErrors[key as keyof Errors] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required.`;
      }
    });

    // Loan amount validation (positive integer)
    if (formData.loan_amount && (!Number.isInteger(Number(formData.loan_amount)) || Number(formData.loan_amount) <= 0)) {
      newErrors.loan_amount = "Loan Amount must be a positive integer.";
    }

    // Phone number validation (10-digit)
    const phoneRegex = /^[0-9]{10}$/;
    if (formData.phone_number && !phoneRegex.test(formData.phone_number)) {
      newErrors.phone_number = "Phone Number must be a valid 10-digit number.";
    }

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === ""); // If no errors
  };


  const handleConfirmAdd = async () => {
    setIsAdding(true);

    const smart_call_auth: any = localStorage.getItem("loginData");
    const smart_call_token = JSON.parse(smart_call_auth).access_token;


    // console.log(formData);

    try {
      const myHeaders = new Headers();
      myHeaders.append("accept", "application/json");
      myHeaders.append("Authorization", `Bearer ${smart_call_token}`);
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      const urlencoded = new URLSearchParams();
      urlencoded.append("phone_number", formData.phone_number);
      urlencoded.append("due_date", formData.due_date);
      urlencoded.append("name", formData.name);
      urlencoded.append("loan_amount", formData.loan_amount);
      urlencoded.append("language", formData.language);

      const requestOptions: any = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
      };

      const response = await fetch(`${API_BASE_URL2}${API_ENDPOINT.agent.createCall}`, requestOptions);
      const result = await response.text();
      // console.log(response);
      // console.log(result);
      if (response.ok && JSON.parse(result).status === "success") {
        toastSuccess(JSON.parse(result).message)
        handleClose();
      } else {
        toastError(result || "Something went wrong. Please try again!");
      }
    } catch (error) {
      toastError("Something went wrong, please try again!");
      console.error(error);
    } finally {
      setIsAdding(false);
      setShowConfirmation(false);
    }
  };

  const handleAddClick = () => {
    if (validateForm()) {
      setShowConfirmation(true);
    }
  };




  return (
    < >
      <div className="agent-details">
        <div className="agent-left-section">
          {/* Profile Image */}
          <img
            src={require("../../../assets/profile-img.png")}
            className="agent-profile-image"
            alt={`${selectedAgent?.agent_name}`}
          />
        </div>

        <div className="agent-right-section">
          <div className="agent-top-row">
            {/* Country Flag */}
            <div className="country-flag">
              <img
                src={countryLogoVikram}
                className="country-flag-image"
                alt={`${selectedAgent?.agent_name}'s Country`}
              />
            </div>

            {/* Agent Name */}
            <h5 className="agent-name">{selectedAgent?.agent_name}</h5>
          </div>
          <hr style={{ margin: "0px 0px 0px 5px", width: "94%" }} />
          <div className="agent-bottom-row">
            <div className="created-date" style={{ margin: "5px" }}>
              <small>Created </small>
              <span className="created-date-text">{selectedAgent?.created_time}</span>
            </div>
            <div className="call-type" style={{ margin: "10px" }}>
              <small>Call Type </small>
              <span className="call-type-name">{selectedAgent?.call_type}</span>
            </div>
            <div className="call-type" style={{ margin: "10px" }}>
              <small>Agent Number </small>
              <span className="call-type-name">{selectedAgent?.agent_phone}</span>
            </div>
            {/* Action Buttons */}
            <div className="d-flex m-3">
              <button
                className="btn-secondary"
                onClick={handleShow}
              >
                Manage Calls
              </button>
            </div>
          </div>
        </div>

        {/* Confirmation Modal */}
        {showConfirmation && (
          <Modal show={showConfirmation} onHide={() => setShowConfirmation(false)} centered>
            <Modal.Header closeButton>
              <Modal.Title>Confirm Action</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure you want to add this to the queue?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowConfirmation(false)}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={handleConfirmAdd}
                disabled={isAdding}
              >
                {isAdding ? "Adding..." : "Yes, Add to Queue"}
              </Button>
            </Modal.Footer>
          </Modal>
        )}


        {show && <Modal show={show} onHide={handleClose} centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title className="d-flex">
              <IoMdCall style={{ marginRight: "10px" }} size={20} /> <h6 style={{ marginBottom: '0px' }}>Create New Call</h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      className="form-control-sm"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      placeholder="Enter name"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Language</Form.Label>
                    <Form.Control
                      type="text"
                      className="form-control-sm"
                      name="language"
                      value={formData.language}
                      onChange={handleInputChange}
                      placeholder="Enter language"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Loan Amount</Form.Label>
                    <Form.Control
                      type="number"
                      className="form-control-sm"
                      name="loan_amount"
                      value={formData.loan_amount}
                      onChange={handleInputChange}
                      placeholder="Enter loan amount"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Due Date</Form.Label>
                    <Form.Control
                      type="date"
                      className="form-control-sm"
                      name="due_date"
                      value={formData.due_date}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="tel"
                      className="form-control-sm"
                      name="phone_number"
                      value={formData.phone_number}
                      onChange={handleInputChange}
                      placeholder="Enter phone number"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-sm " onClick={handleAddClick}>
              Add In Queue
            </Button>
            <Button className="btn-sm " onClick={handleClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>}
      </div>
    </>
  );
}

export default AgentDetails;

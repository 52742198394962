import "./Navbar.css";
import NavAvatar from "./NavAvatar/NavAvatar.tsx";
import React from "react";
function Navbar() {
  return (
    <nav className="header-nav ms-auto">
      <ul className="d-flex align-items-center">
        <NavAvatar />
      </ul>
    </nav>
  );
}
export default Navbar;
